import { useHttpMutation } from '@/shared/hooks';

import { Complete2faStepPayload } from '../../../shared/types';

export const useComplete2faStep = (
  options?: Parameters<typeof useHttpMutation<Complete2faStepPayload, void>>[0],
) => {
  return useHttpMutation<Complete2faStepPayload, void>({
    method: 'POST',
    // api: mockApi,
    ...options,
  });
};
