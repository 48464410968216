import React from "react";

type ClockSvgProps = {
  className?: string;
};

const ClockSvg = ({ className }: ClockSvgProps) => {
  return (
    <svg
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M10.5001 7.9165V11.2498L12.5834 12.4998M10.5001 4.1665C6.58806 4.1665 3.41675 7.33782 3.41675 11.2498C3.41675 15.1619 6.58806 18.3332 10.5001 18.3332C14.4121 18.3332 17.5834 15.1619 17.5834 11.2498C17.5834 7.33782 14.4121 4.1665 10.5001 4.1665ZM10.5001 4.1665V1.6665M8.83341 1.6665H12.1667M17.4409 4.65987L16.1909 3.40987L16.8159 4.03487M3.55927 4.65987L4.80927 3.40987L4.18427 4.03487"
        stroke="#08080C"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default ClockSvg;
