'use client';

import { omit } from 'lodash';

import { useHttpQuery } from '@/shared/hooks';
import { TwoFaResponse } from '@/shared/types';

export const useFetch2faByIdQuery = useHttpQuery<void, TwoFaResponse>;

export function useFetch2faById(
  options?: Partial<Parameters<typeof useFetch2faByIdQuery>[0]>,
) {
  const { id } = options?.params || {};
  const optionsWithoutIDParam = {
    ...options,
    params: omit(options?.params, 'id'),
  };

  return useFetch2faByIdQuery({
    // api: mockApi,
    // url: `/2fas/${id}`,
    url: `/identity/v1/2fas/${id}`,
    queryKey: ['2faById', id],
    ...optionsWithoutIDParam,
  });
}
