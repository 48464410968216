'use client';

import { omit } from 'lodash';
import { FormEventHandler } from 'react';
import { Control, Controller, FieldErrors } from 'react-hook-form';

import { Button } from '@/ui/components/button';
import { Column } from '@/ui/components/flex';
import PhoneInput from '@/ui/components/phone-input';
import {
  countryOptionsForPhoneInput,
  phoneValidationRules,
} from '@/ui/components/phone-input/phone-input-data';
import { TextField } from '@/ui/components/text-field';

import { PhoneNumberFormInputs } from '../hooks/use-phone-number-form';

export interface PhoneNumberFormProps {
  ctaText: string;
  handleSubmit: FormEventHandler<HTMLDivElement>;
  errors?: FieldErrors;
  control: Control<PhoneNumberFormInputs>;
}

export function PhoneNumberForm({
  ctaText,
  handleSubmit,
  errors,
  control,
}: Readonly<PhoneNumberFormProps>) {
  return (
    <Column as="form" gap="units-unit32" onSubmit={handleSubmit}>
      <Column as="fieldset" gap="units-unit20">
        <TextField
          id="phone"
          className="flex-1"
          classNames={{ label: 'font-medium' }}
          label="Your phone number"
          error={errors?.phone?.message as string | undefined}
          renderInput={(props) => (
            <Controller
              name="phone"
              control={control}
              rules={phoneValidationRules}
              render={({ field }) => (
                <PhoneInput
                  {...omit(field, ['ref'])}
                  {...props}
                  error={errors?.phone}
                  aria-required="true"
                  countries={countryOptionsForPhoneInput}
                />
              )}
            />
          )}
        />
      </Column>
      <Column gap="units-unit16">
        <Button
          block
          type="submit"
          aria-label="submit-phone-number-button"
          size="medium"
        >
          {ctaText}
        </Button>
      </Column>
    </Column>
  );
}
