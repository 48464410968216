'use client';

import { isEmpty, omit } from 'lodash';
import { useRouter as useNextRouter } from 'next/navigation';
import { toast } from 'sonner';

import { useSigninFormConfigContext } from '@/features/authentication/signin/providers/sign-in-form-context-provider';

import { useDeviceInfo } from '@/shared/hooks/use-device-info';
import useLogin from '@/shared/hooks/use-login';
import { ROUTES } from '@/shared/routes';
import {
  LoginUserErrorResponse,
  LoginUserPayload,
  LoginUserSuccessResponse,
  getApiErrorMessage,
} from '@/shared/types';

import { baseApi, mockApi } from '@/lib/api';
import { useRouter, useSearchParams } from '@/lib/navigation';
import { TwoFaService } from '@/lib/two-fa-service/two-fa-service';
import { storeAccessToken } from '@/lib/utils';

interface UseSigninFormProps {
  passcodeLength?: number;
  redirectTo?: string;
  mock?: boolean;
}

export const useSignInForm = ({ passcodeLength, mock }: UseSigninFormProps) => {
  const nextRouter = useNextRouter();
  const router = useRouter();
  const { searchParams } = useSearchParams();

  const { config, setConfig } = useSigninFormConfigContext();
  const { deviceInfo, setIsKnown } = useDeviceInfo();
  const twoFaService = TwoFaService.use2faService();

  const headers: Record<string, string> = {};

  if (twoFaService?.twoFaConfig?.id) {
    headers['x-moniepoint-fx-2fa-id'] = twoFaService?.twoFaConfig?.id;
  }

  const onTwoFaSuccess = () => {
    if (!config?.data?.passcode) {
      return router.push(ROUTES.SIGN_IN.INDEX);
    }
    handleSignIn(config.data.passcode);
  };

  const on2faExpiry = () => router.push(ROUTES.SIGN_IN.INDEX);

  TwoFaService.onSuccess = onTwoFaSuccess;
  TwoFaService.onExpiry = on2faExpiry;
  TwoFaService.config = {
    OTP: {
      title: 'Authenticate this device',
      subtitle: `Enter the 6-digit code sent to `,
      passcodeLength: passcodeLength ?? 6,
      showUsername: true,
    },
  };

  const { data } = config ?? {};

  const isValidSession = !!(
    data.username ||
    (data.idToken && data.socialProvider)
  );

  const loginUser = useLogin({
    disableErrorToast: true,
    headers,
    api: mock ? mockApi : baseApi,
    url: mock ? '/logins' : '/identity/v1/logins',
  });

  const handleSignIn = (passcode?: string) => {
    const validPasscode = data.isSSO
      ? true
      : passcode?.length === passcodeLength;

    if (!(isValidSession && validPasscode)) {
      return;
    }

    const loginPayload: LoginUserPayload = {
      ...(data.isSSO
        ? {
            idToken: data.idToken,
            socialProvider: data.socialProvider,
          }
        : {
            username: data.username,
            passcode,
          }),
      deviceInfo: omit(deviceInfo, ['isKnown']),
    };

    loginUser
      .mutateAsync({ data: loginPayload })
      .then((response: LoginUserSuccessResponse, ...args) => {
        // update passcode in signin context

        setConfig({
          ...config,
          data: {
            ...config.data,
            passcode,
          },
        });

        // if response code is 200 save token
        if (response?.accessToken) {
          storeAccessToken(response.accessToken);
        }

        setIsKnown(true);
        const redirect = searchParams.get('redirect') ?? ROUTES.DASHBOARD.INDEX;

        // move forward with redirect
        nextRouter.push(redirect);
        nextRouter.refresh();
      })
      .catch((error: LoginUserErrorResponse, ...args) => {
        setConfig({
          ...config,
          data: {
            ...config.data,
            passcode,
          },
        });

        const { mfa: twoFa, code } = error.response?.data || {};
        const { isKnown = false } = twoFa?.deviceInfo || {};

        setIsKnown(isKnown);

        const is2FaRequiredError = code === '2FA_REQUIRED';

        // Show error only if it is not 2FA required error
        if (!is2FaRequiredError) {
          toast.error(getApiErrorMessage(error));
        }

        if (!isEmpty(twoFa) && is2FaRequiredError) {
          twoFaService.initTwoFaService(twoFa);
        }
      });
  };

  return {
    handleSignIn,
  };
};
