import md5 from 'md5';
import { useEffect, useState } from 'react';
import { UAParser } from 'ua-parser-js';
import { v4 as uuidv4 } from 'uuid';

import { getExistingDeviceInfo, setDeviceInfoCookie } from '@/lib/utils';

import { DeviceInfoCookie, DeviceType } from '../types';

export const useDeviceInfo = (isKnown?: boolean) => {
  const existingDeviceInfo = getExistingDeviceInfo();
  const [deviceInfo, setDeviceInfo] =
    useState<DeviceInfoCookie>(existingDeviceInfo);

  const setIsKnown = (value: boolean) => {
    const updatedDeviceInfo = { ...deviceInfo, isKnown: value };
    setDeviceInfoCookie(updatedDeviceInfo);
    setDeviceInfo(updatedDeviceInfo);
  };

  useEffect(() => {
    if (typeof navigator !== 'undefined' && !existingDeviceInfo) {
      const parser = new UAParser(navigator.userAgent);
      const { ua, device } = parser.getResult();
      const { vendor = 'unknown', model = 'unknown' } = device;
      const guid = uuidv4();
      const deviceId = md5(`${ua}-${guid}`);
      const newDeviceInfo = {
        deviceId,
        type: 'WEB' as DeviceType,
        manufacturer: vendor,
        model,
        isKnown: isKnown || false,
      };
      setDeviceInfoCookie(newDeviceInfo);
      setDeviceInfo(newDeviceInfo);
    }
  }, [existingDeviceInfo, isKnown]);

  return { deviceInfo, setIsKnown };
};
