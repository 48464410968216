'use client';

import { useRouter } from 'next/navigation';

import { useSigninFormConfigContext } from '@/features/authentication/signin/providers/sign-in-form-context-provider';
import { Profile } from '@/features/profile/types';

import { PasscodeVerificationForm } from '@/ui/components/passcode-verification-form';

import { ROUTES } from '@/shared/routes';

import { getCookie } from '@/lib/utils';

import { useSignInForm } from '../hooks/use-sign-in-form';

export function PasscodeVerification() {
  const router = useRouter();

  const { config } = useSigninFormConfigContext();

  const { title, subtitle, passcodeLength = 6 } = config.passcodeVerification;

  const { handleSignIn } = useSignInForm({
    passcodeLength,
  });

  const isValidSession =
    config.data.username || (config.data.idToken && config.data.socialProvider);
  if (!isValidSession) {
    router.push(ROUTES.SIGN_IN.INDEX);
    return null;
  }

  const profileCookie =
    config?.data?.username && getCookie(config.data.username);

  const { firstName, lastName }: Profile = profileCookie
    ? JSON.parse(window.atob(profileCookie))
    : {};

  const name = `${firstName} ${lastName}`;

  return (
    <PasscodeVerificationForm
      passcodeLength={passcodeLength}
      title={profileCookie ? `Welcome back, ${firstName}` : title}
      subtitle={subtitle}
      isKnownUser={!!profileCookie}
      recoverPasscodeLink={ROUTES.PASSCODE_RECOVERY.INDEX}
      user={{ name, profileImageUrl: '' }} // TODO: send profile image url when we get it from backend
      onChange={handleSignIn}
      cancelUrl={ROUTES.SIGN_IN.INDEX}
    />
  );
}
